.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  
    .page {
      max-width: 600vw;
      width: 100%;
      padding: 2rem;
      text-align: center;
  
      .org-logo {
        margin-bottom: 1.5rem;
  
        .org-img {
          max-width: 100px;
          max-height: 100px;
          object-fit: contain;
        }
      }
  
      .text {
        .text-1 {
          font-size: 1.5rem;
          font-weight: bold;
          color: red;
          margin-bottom: 1rem;
          font-family: var(--font-family);
        }
  
        .text-2 {
          font-size: 1rem;
          font-weight: normal;
          color: #555;
          margin-bottom: 1rem;
          line-height: 1.5;
          font-family: var(--font-family);
        }
  
        .text-3 {
          font-size: 0.9rem;
          color: #777;
          margin-bottom: 0.5rem;
          line-height: 1.4;
          font-family: var(--font-family);
        }
      }
    }
  }
  