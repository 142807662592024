.scrollable-container {
    min-height: 390px;
    max-height: 390px;
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
}
.container {
    width: 70% !important;
    margin: auto !important;

    .container-header {
        font-weight: 600;
        color: #333333;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .container-paragraph {
        color: #666666;
        padding-bottom: 25px;
        width: 75%;
        margin: auto;
    }
    .test-module-header-container{
        padding-bottom: 10px;
    .test-module-header {
        color: #666666;
        padding-bottom: 10px;
        margin: auto;
    }
    .test-module-header-border {
        border-bottom: 1px solid #CCCCCC;
        width: 165px !important;  
    }
    .test-moduleI-header-border {
        border-bottom: 1px solid #CCCCCC;
        width: 100px !important;  
    }
}
}
.content-footer {
    position: relative;
    bottom: 40px;
    margin-top:2rem;

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .resume-button {
            border: 1px solid #CCCCCC;
            border-radius: 10px;
            opacity: 1;
            padding: 0.5rem 1rem;
            text-transform: none;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            width: 140px;
            height: 48px;
            margin-top: 2rem;
            font-family: var(--font-family);
        }

        .start-button {
            border-radius: 10px;
            opacity: 1;
            background: var(--primary-color) 0% 0% no-repeat padding-box;
            padding: 0.5rem 1rem;
            text-transform: none;
            width: 118px;
            height: 48px;
            margin-top: 2rem;
            font-family: var(--font-family);
        }
    }

    .footer-text {
        letter-spacing: -0.06px;
        color: #999999;
        opacity: 1;
        font-size: 0.8rem;
        margin-top: 2rem
    }
}

.content-footer::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 40px;
    top: -75px;
    box-shadow: 0 57px 12px 0 rgba(255, 255, 255, 0.62), 0 54px 52px 0 rgb(255, 255, 255) !important;
    pointer-events: none;
}

@media (max-width:500px) {
    .container {
        width: 100% !important;

        .container-paragraph {
            width: 100%;
        }
    }

    .user-container {
        .user-info {
            .user-name {
                font-size: 14px;
            }
        }
    }
}