
.parent-container{
.card {
  border-radius: 20px;
  box-shadow: none ;
  border: 1px solid #FFFFFF;
  margin-bottom: 1rem !important;
  padding: 0px;
  .card-content{
    padding-bottom: 5px;
  .card-body {
    display: flex;
    .card-index {
      margin-top: 10px
    }
    .card-inactive-index{
      margin-top: 0px
    }
    .card-content {
      width: 100%;
      margin-left: 16px;
  
      .card-content-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .card-title {
          margin-right: 0.5rem;
        }
        .edit-button {
          border-radius: 10px !important;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
          font-size: 0.85rem !important;
          border: 1px solid #CCCCCC;
          border-radius: 10px;
          opacity: 1;
          text-transform: none;
          background: #FFFFFF 0% 0% no-repeat padding-box;
        }
  
        .start-button {
          border-radius: 10px;
          font-family: var(--font-family);
          font-size: 0.85rem;
          color: white;
          opacity: 1;
          background: var(--primary-color) 0% 0% no-repeat padding-box;
          text-transform: none;
          height: 38px !important;
          width: 84px !important;
        }
  
        .disabled-button {
          border-radius: 10px;
          font-family: var(--font-family);
          font-size: 0.85rem;
          border: 1px solid #CCCCCC4D;
          opacity: 1;
          background: var(--unnamed-color-226cf4) 0% 0% no-repeat padding-box;
          background: #ffffff 0% 0% no-repeat padding-box;
          color: black;
          text-transform: none;
          height: 38px !important;
          width: 84px !important;
        }
  
        .edit-button {
          border-radius: 10px;
          font-size: 0.85rem;
          background-color: #FFFFFF;
          border-radius: 10px;
          box-shadow: none;
          color: #333333;
          border: 1px solid #CCCCCC4D;
          opacity: 1;
          height: 38px !important;
          width: 84px !important;
  
          &:hover {
            background-color: #FFFFFF;
            box-shadow: none;
            color: #333333;
            border: 1px solid #CCCCCC4D;
            opacity: 1;
          }
        }
      }
  
      .card-content-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: "24px";
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      }

    .button-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0 7px;
      padding: 0;
      margin-top: 15px;

      .chip-selected {
        color: #FAFAFA;
        background-color: green;
        border-radius: 0.5rem;
        padding: 0 !important;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        width: 30px;
        height: 32px;
        max-width: 100%;
      }

      .chip {
        color: #000000;
        background-color: #ffffff;
        border-radius: 0.5rem;
        padding: 0 !important;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        width: 30px;
        height: 32px;
        max-width: 100%;
       }
      }
    }
  }
}
}
.card-selected{
  border: 1px solid #4098EC !important;
  background-color: #DAE0FD;
}
.card-not-selected{
  border: 1px solid #CCCCCC !important;
  background-color: white;
}
}

.css-11lqbxm {
  padding: 0 !important;
}

.css-1jzq0dw-MuiChip-label {
  padding: 0 !important;    
}

.grid-item {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
