.navbar{
background-color: white !important;
box-shadow: none !important;
.toolbar {
    height: 64px;
    background-color: white !important;
    color: black !important;
    display: flex;
    align-items: center;
    width:90%;
    margin: auto;
    padding: 12px 0;

    .back-button{
        height: 42px;
        cursor: pointer;
        border-radius: 5rem;
        margin-right: 5px;
    }
    .back-button:hover {
        background-color: #d6d3d3;
    }

    .nav-logo {
        height: 48px;
        width: 48px;
        top: 32px;
        left: 140px;
        opacity: 1
    }

    .timer-combined{
        margin-left : 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-button{
        padding: 0px;
        pointer-events: none;
        .clock {
            opacity: 1;
            font-size: 3rem;
            padding: 0px;
            top: 20px;
        }
    }
    .timer-text{
        color: #999999;
    }
     .right-side-item{
        .buttons{
            text-transform: none;
        }
        .user-container {
            .user-info {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
        
                .user-name {
                    font-weight: bold;
                    font-size: 16;
                    line-height: 1.2;
                    margin-bottom: 0.5
                }
            }
        
            .user-icon {
                height: 40px;
                width: 40px;
                margin-left: 1rem;
                top: 32px;
                left: 140px;
                opacity: 1
            }
        }
     }
}
}

@media (max-width: 500px) {
    .toolbar {
        padding: 12px 0;
        min-height: 40px !important;
        .nav-logo {
            height: 40px;
        }
    }
}

.invalid-url-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;

    .invalid-url{
        text-align: center;
        max-width: 60vw;

        .org-logo{
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .org-img{
                height: 150px;
                width: 150px
            }
        }

        .url-text{
            .url-text-one{
                font-size: 36px;
                margin-bottom: 20px;
                font-family: var(--font-family);
            }
    
            .url-text-two{
                color: #666;
                margin-bottom: 20px;
                font-size: 24px;
                font-family: var(--font-family);
            }
        }

    }
}