.question-card {
  box-shadow: none !important;


  .question-number {   //represent number of questins out of total questions
    margin-bottom: 1rem;
  }

  .question-text {   //represents the style of the questions
    font-size: large;
  }

  .option-box {   //represents the box container for the options
    border: 1px solid #CCCCCC4D; 
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    .option-box:hover {
      cursor: pointer;
    }
    .option-box * {
      cursor: inherit;
    }
    .option-row {  //the row in which we are showing the data
      display: flex;
      align-items: center;
      pointer-events: none;

      .option-label {  // label for the questions here it is A , B , C , D of different color from parent
        margin-right: 1.5rem;
        color: #666666;
        margin-right: 0.5rem;
        font-size: 14px;
      }

      .option-text {   // the text inside the container holding names for the questions
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        flex-grow: 1;
        border: none;
        outline: none;
        font-size: 14px;
        width: 100%;
        align-content: center;
        resize: none;
        white-space: pre-wrap;
        overflow: hidden;
        font-family: inherit;
        line-height: 1.5;
      }

      .radio-option {   //the radio option we have changed the radio button syle as per the requirement
        margin-left: 0rem !important;
        margin-left: 8px;

        .form-control {  //matching the radio button as per the styling
          margin: 0px;
        }
      }
    }
  }
} 