.question-module {
  // the second section of the screen where we can see the questions from the modules
  display: flex;
  justify-content: center;

  .styled-dialog-box {
    //outside scrollable dialog
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #CCCCCC80;
    border-radius: 1.5rem !important;

    .dialog-content {
      // the container for all the questions and response
      padding : 15px;
      max-height: 61vh;
      min-height: 61vh;
      overflow: scroll;
    }

    .dialog-content::-webkit-scrollbar { 
      width: 0 !important;
      background-color: transparent !important;
    }


    .attempted-ques-status {
      //the bottom green line representing the total out of total
      .done-icon {
        display: inline;
        vertical-align: middle;
        margin-right: 0.5rem;
      }
      .green-color-text{
        color : green;
      } 
      .red-color-text{
        color : #ff3d04;
      }
      .yellow-color-text{
        color : #8a5e00;
      }

      padding: 22px;
      color: green;
    }
  }
}