.nav-button {
  // for adjusting the nav bar button as per the design
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
  font-size: 16px;
  width: 100px;
  height: 45px;
  text-transform: none !important;
}

.exam-screen-container {
  // for adjusting the main container as per the design  
  width: 90% !important;
  margin: auto !important;
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;

  .module-container-div {
    padding-left: 0px !important;
    flex: 1;

    .module-container {
      max-height: 69vh;
      min-height: 69vh;
      overflow: scroll;
      padding: 1rem;
      background-color: #fafafa;
      border-radius: 1rem;
      scrollbar-width: none;
      .module-heading {
        color: #666666;
    }
    }
    .module-container::-webkit-scrollbar { 
      width: 0 !important;
      background-color: transparent !important;
    }

  }
  .class-container{
    padding-left: 0px !important; 
    flex: 1;
  }
}