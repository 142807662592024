.test-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 260px;
    padding: 16px;
    border-radius: 10px !important;
    position: relative;
    box-shadow: none !important;
    border: 1px solid #CCCCCC4D;
    margin-bottom: 1rem;

    .test-card-box {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #E6F3FF;

        .test-card-number {
            padding: 0;
            font-size: 15;
            color: var(--primary-color);
            line-height: 30px,
        }
    }
    .test-card-icon {
        width: 78px;
        height: 78px;
        margin-top: 16px
    }
        .card-content {
            text-align: center;
            padding: 0;
            .test-card-title{
                padding-top: 16px;
            font-weight: 500;
            font-size: 24px;
            color: #333333;
            margin-top: 8px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            }
            .test-card-description{
                padding-top: 12px;
                font-weight: 300;
                font-size: 14px;
                color: #666666;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
    
}