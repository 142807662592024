.vid-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .video-responsive {
        width: 100%;
        height: 550px;
        background-color: #000;
        border-radius: 16px;
        margin: 10px 0;
    }
}
  
  