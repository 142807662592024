.testcomplete-container{
  position: fixed;
  background: #fff;
  inset: 0;
  background: #fff;
  width: 100vw !important;
  position: fixed;
  inset: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  .testcomplete-container-box {
      width: 100% !important;
      margin: auto !important;
    .testcomplete-icon{
      padding-bottom: 50px;
      width: 78px;
      height: 78px;
    }
    .testcomplete-header{
      padding-bottom: 20px;
      color: var(--unnamed-color-333333);
      text-align: center;
      font-weight: 600;
      letter-spacing: -0.13px;
      color: #333333;
      opacity: 1;
    }
        .testcomplete-content {
            text-align: center;
            letter-spacing: -0.07px;
            color: #666666;
            opacity: 1;
            padding-bottom: 60px;
        }
        .testcomplete-footer {
          text-align: center;
          color: #999999;
          letter-spacing: -0.06px;
          opacity: 1;
          .text-email{
              color : #226CF4;
          }
      }
  }
}
