.bootstrap-dialog {
  & .MuiPaper-root {
    border-radius: 1rem;
  }
  
  & .MuiDialogContent-root {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  .icon-button-cross {
    position: absolute;
    right: 8px;
    top: 8px;
    color : grey;
  }

  .dialog-content {
    .img-icon {
      height: 80px;
      width: 80px;
      margin-bottom: 2rem;
    }

    .typography-main {
      color: #333333;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 28px;
      font-weight: bold;
    }

    .typography-sub {
      color: #999999;
      margin-bottom: 2rem;
      text-align: center;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .submit-button{
        margin-right: 0.25rem;
      }
      .cancel-button{
        background-color: #DFE1E4; 
        margin-left: 0.25rem;
        color: black; 
        box-shadow: none;
      }
    }
  }
}