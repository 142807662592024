.technical-main-screen{
width: 92%;
height: calc(100vh - 120px);
margin: auto;   
margin-top: 1rem;
.technical-test-screen {
    position: relative;
    overflow: hidden;
    .code-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 16px;
        .buttons-run-code{
            height: 40px !important;
            width: 118px;
            border-radius: 2px;
            border-radius: 9px;
            margin-left: 0.5rem;
            font-size: 12px;
            text-transform: none;
        }
        .buttons-submit-questions{
            height: 40px !important;
            width: 138px;
            border-radius: 2px;
            border-radius: 9px;
            margin-left: 0.5rem;
            font-size: 12px;
            text-transform: none;
        }
    }
    .result-box{
        background-color: #FAFAFA;
        border: 0px;
        margin-bottom: 21px;
        margin-top: 21px;
    }
    .question-language-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 5px;
        .mode-icon{
            padding: 7px;
            border: 1px solid #ccc;
            margin-left: auto;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .reset-icon{
            padding: 8px;
            border: 1px solid #ccc;
            color: rgb(81, 147, 247);
            margin-left: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .css-1rcabp{
            margin-bottom: 0 !important;
        }
    }
  }
  
  .question-box {
    display: flex;
    flex-direction: column;
    .question-header{
        padding: 10px 0px;
        // border-bottom: 0.5px solid;
        display: flex;
        align-items: center;
        .logo-icon{
            margin-left: 6px;
        }
        .img-icon{
            cursor: pointer;
        }
        .timer{
            margin-left: auto;
        }
    }
    .question-chip-box{
        padding: 12px 0px;
        margin-top:-12px;
        .question-title{
            font-size: 20px;
        }
        .question-chip-easy {
            width: 65px;
            background-color: #ade2b4e0;
            color: #004d0ae0;
            text-align: center;
            border-radius: 5px;
            height: 24px;
        }
        .question-chip-moderate{
            width: 65px;
            color: #e2b500e0;
            background-color: #f8faa0e0;
            text-align: center;
            border-radius: 5px;
            height: 24px;

        }
        .question-chip-hard{
            width: 65px;
            color: #8d0d04e0;
            background-color: #ffb3ade0;
            text-align: center;
            border-radius: 5px;
            height: 24px;
        }
    }
    .question-data{
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
        margin-top: -4px;
        padding: 1rem;
        height: 82vh;
        .test-cases{
            display: flex;
            flex-direction: column;
            .test-cases-box{
                border: 1px solid #ccc;
                padding: 10px;
                margin-top: 12px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                align-items: spac;
                background-color: white;
            }
        }
    }  
 }
}
  .custom-handle {
    position: absolute;
    right: 0;
    top: 49%;
    bottom: 0;
    width: 7px;
    height: 0px;
    cursor: ew-resize;
    background-color: #f0f0f0;
    border-left: 1px solid #ccc;
  }
  .custom-handle::before {
    content: '';
    display: block;
    width: 5px !important;
    margin: 0 2px !important;
    height: 40px !important; /* Centered line for better grab visibility */
    background-color: #888 !important; /* Slightly darker color for the inner line */
    border-radius: 2px !important;
  }
  

