.domain-page-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;

    .domain-page{
        text-align: center;
        max-width: 80vw;

        .org-logo{
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .org-img{
                height: 150px;
                width: 150px
            }
        }

        .text{
            .text-one{
                font-size: 36px;
                margin-bottom: 20px;
                font-family: var(--font-family);
            }
    
            .text-two{
                color: #666;
                margin-bottom: 20px;
                font-size: 24px;
                font-family: var(--font-family);
            }

            .text-three{
                font-family: var(--font-family);
                font-size: 18px;
            }
        }

    }
}