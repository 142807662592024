.instruction-div {
  width: 70%;
  margin: auto;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3rem;
  border: 1px solid lightgrey;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  background-clip: padding-box; /* Ensures content respects the border */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: lightgrey transparent; /* Scrollbar thumb and track colors */
}
.instruction-content-footer {
  position: relative;
  bottom: 40px;
  margin-top:2rem;

  .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .resume-button {
          border: 1px solid #CCCCCC;
          border-radius: 10px;
          opacity: 1;
          padding: 0.5rem 1rem;
          text-transform: none;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          width: 140px;
          height: 48px;
          margin-top: 2rem;
          font-family: var(--font-family);

      }

      .start-button {
          border-radius: 10px;
          opacity: 1;
          background: var(--unnamed-color-226cf4) 0% 0% no-repeat padding-box;
          background: #226CF4 0% 0% no-repeat padding-box;
          padding: 0.5rem 1rem;
          text-transform: none;
          width: 118px;
          height: 48px;
          margin-top: 2rem;
          &.disabled {
              background: #c7c7c7 0% 0% no-repeat padding-box;
          }
          font-family: var(--font-family);
      }
  }

  .footer-text {
      letter-spacing: -0.06px;
      color: #999999;
      opacity: 1;
      font-size: 0.8rem;
      margin-top: 2rem
  }
}

.instruction-content-footer::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 40px;
  top: -75px;
  pointer-events: none;
}

/* Custom scrollbar for WebKit browsers */
.instruction-div::-webkit-scrollbar {
  width: 6px;
}

.instruction-div::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px; /* Rounded scrollbar */
}

.instruction-div::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}


.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #666666;
}
